import Layout from "../components/layout"
import SEO from "../components/seo"
import React from "react"
import { connect } from "react-redux"
import { toggleLanguage } from "../state/actions"
import { graphql } from "gatsby"
import Link from "../components/link"
import Section from "../components/section"
import SectionInner from "../components/section-inner"

const Error404 = ({ toggleLang, lang, data }) => {

  const pagePaths = data.allSitePage.nodes.map(node => node.path)

  // return (<AllComponents tag="hero" />)

  return (
    <Layout>
      <SEO title="Home"/>
      <Section className="Section Section--vPadding">
        <SectionInner>
          <ul className="">
            {pagePaths.map(
              (pagePath, index) =>
                index < 100 && (
                  <li key={pagePath}>
                    <Link to={pagePath} className="c-accent-dark">{pagePath}</Link>
                  </li>
                ),
            )}
            {pagePaths.length > 100 && (
              <p style={{ fontWeight: `bold` }}>
                ... and {pagePaths.length - 100} more.
              </p>
            )}
          </ul>
        </SectionInner>
      </Section>

    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  }
}

const mapDispatchToProps = {
  toggleLang: toggleLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Error404)

export const pageQuery = graphql`
    query {
        allSitePage {
            nodes {
                path
            }
        }
    }`
